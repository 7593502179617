import React from "react"
import { graphql, Link, navigate } from "gatsby"
import { Layout } from "@components/Layout"
import { SEO, mapToSEO } from '@components/SEO'
import { Segment, Dimmer, Loader, Grid, List, Divider } from "semantic-ui-react"
import { mapToOrderConfirmation } from "@mappers/OrderConfirmation/mapper"
import OrderConfirmation from "@components/OrderConfirmation/OrderConfirmation"
import { GenericBannerContainer, UserAccountWrapperContainer } from "@components/GenericBannerContainer"
import theme from "@styles/theme"
import { getCurrentCheckout } from "@redux/localStorage/checkout"
import { NavListItemContent, NavListItemHeader } from "@privateComponents/private.styles"
import { DealerManageContentArea } from "@components/OrderConfirmation/OrderConfirmation.styles"
import { NavListItemBottomWrap } from "@components/Dealer/DealerOrderTypeCard.style"
import { NavListItem } from "@components/Dealer/DealerSignIn.style"
import DealerList from "@components/Dealer/DealerList"
import DealerNav from "@components/Dealer/DealerNav"
import { DealerService } from "@services/DealerService"

const queryString = require('query-string');
interface Props {
  data: any;
  location?: {
    state?: any
  }
}
type ConfirmationPageState = {
  processing: boolean,
  transactionId?: string
}
class OrderConfirmationPage extends React.Component<Props, ConfirmationPageState>{

  constructor(props: Props) {
    super(props);
    this.state = {
      processing: true,
      transactionId: undefined
    }
  }
  componentDidMount() {
    const query = queryString.parse(window.location.search);
    const transactionId = query.rcpt ? query.rcpt : undefined;
    this.setState({ processing: false, transactionId: transactionId });
  }
  render() {
    const { elements } = this.props.data.kontentItemOrderConfirmationPage;
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const siteMetadata = this.props.data.site.siteMetadata;
    const seoData = mapToSEO(elements);
    const orderConfirmationData = mapToOrderConfirmation(elements);
    const checkout = getCurrentCheckout()

    const dealerName = checkout.dealerDetails?.dealerName
    const salesPersonFirstName = checkout.dealerDetails?.salesPersonName.split(' ')[0]
    const salesPersonEmail = checkout.dealerDetails?.salesPersonEmail.split(' ')[0] || ""
    const salesPersonId = `${checkout.dealerDetails?.salesPersonId}` || ""

    const dealerOptions = [
      {
        key: dealerName,
        text: dealerName,
        value: dealerName,
        selected: true
      }
    ]


    return (<Layout version="simple">
      <SEO {...seoData} />
      {
        this.state.processing ?
          <Dimmer active>
            <Loader content="Authorizing your payment..." />
          </Dimmer>
          :
          checkout.isDealerOrder ?
            <>
              <UserAccountWrapperContainer bgLeftColor={theme.brand.colors.blue}
              mobilePaddingBottom={0}
              mobilePaddingTop={0}>
                  <Grid>
                      <Grid.Row>
                          <Grid.Column only="computer"
                          computer={4}
                          style={{
                              float: "right",
                              backgroundColor: theme.brand.colors.lightToGrey,
                              minHeight: "calc(100vh - 180px)",
                              paddingTop: "70px",
                            }}
                          >
                          <List selection verticalAlign="middle" size="huge">
                              <DealerList dealerOptions={dealerOptions}  apiUrl={apiUrl} salesPersonEmail={salesPersonEmail}/>
                              <Divider/>
                              <DealerNav activeCss={['item-active', '', '']}/>
                          </List>
                          </Grid.Column>
                          <DealerManageContentArea mobile={16} tablet={16} computer={12}>
                            <OrderConfirmation siteMetadata={siteMetadata} orderConfirmationContent={orderConfirmationData} transactionId={this.state.transactionId} location={this.props.location}></OrderConfirmation>
                          </DealerManageContentArea>
                      </Grid.Row>
                  </Grid>
              </UserAccountWrapperContainer>
            </>
          :
          <Segment basic style={{backgroundColor:theme.brand.colors.grey}}>
            <GenericBannerContainer
              padding={{
                mobile: {
                  top: 20,
                  bottom: 60
                },
                desktop: {
                  top: 40,
                  bottom: 80
                }
              }}
              backgroundColor={theme.brand.colors.grey} paddingTop={40} paddingBottom={80}>
              {
                !this.state.processing &&
                <OrderConfirmation siteMetadata={siteMetadata} orderConfirmationContent={orderConfirmationData} transactionId={this.state.transactionId} location={this.props.location}></OrderConfirmation>
              }
            </GenericBannerContainer>
          </Segment>
      }

    </Layout>
    );
  }
}

export const query = graphql`{
  site {
    siteMetadata {
      apiUrl
      environment
      lastBuilt
    }
  }

  kontentItemOrderConfirmationPage {
    elements {
      create_new___full_payment {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            elements {
              body {
                value
              }
              heading {
                value
              }
            }
          }
        }
      }
      create_new___full_payment___manufacture_later {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            elements {
              body {
                value
              }
              heading {
                value
              }
            }
          }
        }
      }
      create_new___gifting_payment {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            elements {
              heading {
                value
              }
              body {
                value
              }
            }
          }
        }
      }
      create_new___layby_payment {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            elements {
              heading {
                value
              }
              body {
                value
              }
            }
          }
        }
      }
      redesign___full_payment___manufacture_later {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            elements {
              body {
                value
              }
              heading {
                value
              }
            }
          }
        }
      }
      redesign___full_payment___manufacture_now {
        linked_items {
          ... on KontentItemOrderConfirmationMessage {
            elements {
              heading {
                value
              }
              body {
                value
              }
            }
          }
        }
      }
      dealer___manufacture_later{
        linked_items{
          ... on KontentItemOrderConfirmationMessage{
            elements{
              heading{
                value
              }
              body{
                value
              }
            }
          }
        }
      }
      dealer___on_request{
        linked_items{
          ... on KontentItemOrderConfirmationMessage{
            elements{
              heading{
                value
              }
              body{
                value
              }
            }
          }
        }
      }
      dealer___manufacture_now{
        linked_items{
          ... on KontentItemOrderConfirmationMessage{
            elements{
              heading{
                value
              }
              body{
                value
              }
            }
          }
        }
      }
      multiple_order{
        linked_items{
          ... on KontentItemOrderConfirmationMessage{
            elements{
              heading{
                value
              }
              body{
                value
              }
            }
          }
        }
      }
      dealer___top_review_statement {
        linked_items{
          ... on KontentItemOrderConfirmationMessage{
            elements{
              heading{
                value
              }
              body{
                value
              }
            }
          }
        }
      }
      url{
        value
      }
      page_info___checkout__title{
        value
      }
      page_info___checkout__progress{
        value{
          codename
          name
        }
      }
      seo__nofollow {
        value {
          codename
          name
        }
      }
      seo__noindex {
        value {
          codename
          name
        }
      }
      seo__page_description {
        value
      }
      seo__page_title {
        value
      }
    }
  }
}
`

export default OrderConfirmationPage
